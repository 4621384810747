<template>
  <div>
    <div class="initiate">
      <div
        class="item_on"
        v-for="(item, index) in allList"
        :key="index"
        @click="detail(item)"
      >
        <div class="flex item_i">
          <div class="flex">
            <div style="font-weight: bolder; font-size: 20px">
              {{ item.initiatorName }}
            </div>
            <div class="title_i">{{ item.tradeType }}{{ item.orderType }}</div>
          </div>
          <div class="flex" style="margin-right: 10px">
            <div style="margin-right: 100px; color: #ccc">
              {{ item.createTime.replace("T", " ") }}
            </div>
            <div style="font-size: 16px">
              {{ item.confirmStatus | confirmStatus }}
            </div>
          </div>
        </div>
        <div class="flex introduction">
          <div class="taxInc">
            {{ item.taxIncluded | taxIncluded }}{{ item.priceType | priceType }}
          </div>
          <div
            style="
              margin-left: 30px;
              margin-left: 30px;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              max-width: 360px;
            "
            :title="item.goodsName"
          >
            商品名称:{{ item.goodsName }}
          </div>
          <div
            :title="item.listingPrice"
            style="
              margin-left: 30px;
            "
          >
            价格:
            <span
              style="
                color: red;
                font-size: 17px;
                display: inline-block;
                max-width: 90px;
                vertical-align: bottom;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              ">
              {{ item.listingPrice }}</span
            >元/吨
          </div>
          <div style="margin-left: 30px">
            订单数量:
            <span v-if="item.ifConsultRealityCount == 0"
              ><span style="color: red; font-size: 17px">{{
                item.initialCount
              }}</span>
              吨</span
            >
            <span v-else>以实际结算数量为准</span>
          </div>
          <div style="margin-left: 30px">
            配送方式:{{formatDeliveryMode(item)}}
          </div>
        </div>
        <div
          style="display: flex; justify-content: right"
          v-if="item.confirmStatus == 2"
          @click.stop="deteleItem(item)"
        >
          删除
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deleteInviteGoods } from "@/api/foundation";
import Vue from "vue";
import * as types from "@/store/action-types";
export default {
  data() {
    return {
      allList: [],
    };
  },

  created() {},
  props: {
    list: Array,
  },
  computed:{
    formatDeliveryMode(){
      return val=>{
        let arr = ['','送货','自提'];
        if(val.tradeType=='挂牌'&&val.orderType=='销售'){
          return arr[val.deliveryMode] + '(以实际拉运方式为准)'
        }else{
          return arr[val.deliveryMode]
        }
      }
    }
  },
  filters: {
    taxIncluded(val) {
      switch (val) {
        case 0:
          return "含税";
          break;
        case 1:
          return "不含税";
          break;
      }
    },
    priceType(val) {
      switch (val) {
        case 1:
          return "坑口价";
          break;
        case 2:
          return "出厂价";
          break;
        case 3:
          return "船板价";
          break;
        case 4:
          return "平仓价";
          break;
        case 5:
          return "车板价";
          break;
        case 6:
          return "到厂价";
          break;
      }
    },
    confirmStatus(val) {
      let arr = ["未处理", "已形成订单", "已拒绝"];
      return arr[val];
    },
    deliveryMode(val) {
      switch (val) {
        case 1:
          return "送货";
          break;
        case 2:
          return "自提";
          break;
      }
    },
  },
  watch: {
    list(val) {
      for (let i = 0; i < val.length; i++) {
        if (val[i].orderType == 1) {
          val[i].orderType = "销售";
        }
        if (val[i].orderType == 2) {
          val[i].orderType = "采购";
        }
        if (val[i].tradeType == 1) {
          val[i].tradeType = "挂牌";
        }
        if (val[i].tradeType == 2) {
          val[i].tradeType = "竞价";
        }
      }
      this.allList = val;
    },
  },
  methods: {
    deteleItem(item) {
      let config = {
        goodsId: item.goodsId,
        searchType: "2",
      };
      deleteInviteGoods(config).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.$emit("change", res);
        }
      });
    },
    detail(item) {
      Vue.ls.set("ItemOn", item);
      this.$router.push({
        path: "/InvitationDetailed",
        query: {
          goodsName: item.goodsName,
          goodsNum: item.goodsNum,
          receiverName: item.receiverName,
          receiverTelephone: item.receiverTelephone,
          taxIncluded: item.taxIncluded,
          priceType: item.priceType,
          listingPrice: item.listingPrice,
          initialCount: item.initialCount,
          // fileId: item.contractInfoList[0].fileId,
          goodsId: item.goodsId,
          confirmStatus: item.confirmStatus,
          inviteEnterpriseId: item.inviteEnterpriseId,
          viewSet: JSON.stringify(item.viewSet),
          ifConsultRealityCount: item.ifConsultRealityCount,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.taxInc {
  background: #d0111a;
  color: #fff;
  padding: 2px 10px;
}

.flex {
  display: flex;
  align-items: center;
}

.title_i {
  margin-left: 10px;
  padding: 8px 20px;
  background: #fa9120;
  color: #fff;
  border-radius: 10px;
}

.initiate {
  background: #fff;
  padding: 20px;
}

.item_on {
  margin-top: 20px;
  /* border: 2px  #ccc; */
  border: 1px solid #ccc;
  padding: 10px;
}

.item_i {
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px dashed #ccc;
  padding-bottom: 10px;
}

.introduction {
  margin-top: 10px;
}
</style>
